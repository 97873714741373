import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { DsgvoComponent } from './dsgvo/dsgvo.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { LoginComponent } from './login/login.component';
import { ProjekteComponent } from './projekte/projekte.component';
import { SmarthomeComponent } from './smarthome/smarthome.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'projekte', component: ProjekteComponent },
  { path: 'aboutUs', component: AboutUsComponent },
  { path: 'smarthome', component: SmarthomeComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'dsgvo', component: DsgvoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
