import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';


const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

@Component({
  selector: 'app-smarthome',
  templateUrl: './smarthome.component.html',
  styleUrls: ['./smarthome.component.css']
})
export class SmarthomeComponent implements OnInit {
  
  apiUrl: string = "https://www.winkler-it.com/winklerit/sendMail.php";
  auth: any = "Steinsdorf2020!";
  name:string ="";
  email:string ="";
  telefon: number = 0;
  betreff:string = "";
  text: string = "";
  date: string ="";
  dsgvo: boolean = false;
  
  agb: boolean = false;
  success:string = "";
  error:string = "";


  constructor(private httpClient: HttpClient) { }

  sendPostRequest(data: Object): Observable<Object> {
    return this.httpClient.post(this.apiUrl, data);
  }

  checkMail(){
    console.log("Test");
    if(this.agb && this.dsgvo){
    let data = {
      "name": this.name,
      "text": this.text,
      "email": this.email,
      "subject": "Neue Anfrage",
      "telefon" : "123",
      "date": this.date
     }

    if(this.name  && this.email && this.agb  && this.text && this.dsgvo){
      this.error = "";
        console.log(data);
        this.sendPostRequest(data).subscribe(val=>{
          if(val==1){
            this.success = "Email erfolgreich verschickt!";
          }else{
            this.error = `Fehler aufgetreten +${val}`;
          }
        })
    }else{
      this.error = "Bitte alle Felder ausfüllen und die Datenschutzerklärung akzeptieren!";
    }
  }else{
    this.error = "Bitte alle Felder ausfüllen und die Datenschutzerklärung akzeptieren!";
  }
}


  ngOnInit(): void {
  }

}
